import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NoRowsOverlay from '../components/NoRowsOverlay';
import { invokeApig } from '../libs/awsLib';
import NeokodeButton from '../components/NeokodeButton';
import moment from 'moment';
import '../assets/css/table.css';
import FilterPanel from '../containers/support/FilterPanel';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  breadcrumbContainer: {
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    margin: '0',
  },
  changeButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  },
  searchInput: {
    marginRight: '10px',
  },
  editIcon: {
    color: '#2196f3',
  },
  enableIcon: {
    color: '#00b31f',
  },
  disableIcon: {
    color: '#f44336',
  },
  rootSearch: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textFieldSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
  refreshIcon: {
    color: '#666',
    margin: '0 15px'
  },
  button: {
    margin: '15px',
  },
  confirmUserText: {
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#00b31f',
    color: '#ffffff',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#ffffff',
  },
  yellow: {
    backgroundColor: '#ff9800',
    color: '#ffffff',
  },
  blue: {
    backgroundColor: '#2196f3',
    color: '#ffffff',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.rootSearch}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Buscar…"
        className={classes.textFieldSearch}
        InputProps={{
          startAdornment: <Icons.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Limpiar"
              aria-label="Limpiar"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <Icons.Clear fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <IconButton className={classes.refreshIcon} onClick={props.onRefresh}>
        <Icons.Refresh />
      </IconButton>
      <NeokodeButton
        variant="contained"
        className={classes.addButton}
        startIcon={<Icons.Add />}
        component={RouterLink}
        disabled={props.isLoading}
        to="/tickets/create"
      >
        Agregar Ticket
      </NeokodeButton>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function TicketsPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [tickets, setTickets] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getTickets();
  }, []);

  const getTickets = async (filter) => {
    try {
      setIsLoading(true);
      setIsGlobalError(false);
      setTickets([]);
      setRows([]);
      setSearchText('');
      try {
        let lastKey = undefined;
        if (!filter) {
          filter = {};
        }
        let newTickets = [];
        do {
          const res = await invokeApig({
            path: "/support/find",
            method: "POST",
            body: {
              "LastEvaluatedKey": lastKey ? lastKey : undefined,
              "limit": 100,
              "startDate": filter.startDate,
              "endDate": filter.endDate,
              "status": filter.status,
            }
          });
          if (res.code === 200) {
            newTickets = [...newTickets, ...res.payload.tickets];
            setTickets(newTickets);
            setRows(newTickets);
            setIsLoading(false);
            lastKey = res.payload.LastEvaluatedKey;
          } else {
            setMessage('Error al obtener los tickets. Intenta nuevamente o comunícate con Soporte');
            setIsGlobalError(true);
            break;
          }
        } while (lastKey !== null);
      } catch (e) {
        this.setState({isError: true});
      }
    } catch (error) {
      console.error('Error in getTickets:', error);
      setMessage('Error al obtener los tickets. Intenta nuevamente o comunícate con Soporte');
      setIsGlobalError(true);
    }
    setIsLoading(false);
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = tickets.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const getColorCause = (cause) => {
    let res = undefined;
    switch (cause) {
      case 'Error': {
        res = classes.red;
        break;
      }
      case 'Sugerencia': {
        res = classes.blue;
        break;
      }
      case 'Consulta': {
        res = classes.yellow;
        break;
      }
    }
    return res;
  }

  const getColorState = (state) => {
    let res = undefined;
    switch(state) {
      case 'Sin asignar':
        res = classes.yellow;
        break;
      case 'Resuelto':
        res = classes.green;
        break;
      case 'En progreso':
        res = classes.blue;
        break;
      default:
        res = undefined;
    }
    return res;
  }

  const handleRowClick = (param) => {
    history.push(`/tickets/edit/${encodeURIComponent(param.row.id)}`);
  }

  const handleFilter = (data) => {
    getTickets(data);
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id ticket',
    },
    {
      field: 'cause',
      headerName: 'Causa',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Chip
          size="small"
          label={params.value}
          className={getColorCause(params.value)}
        />
      ),
    },
    {
      field: 'subject',
      headerName: 'Asunto',
      flex: 1,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha',
      flex: 1,
      renderCell: (params) => (
        moment(params.value).utcOffset(params.row.creationDateZone).format("DD-MM-YYYY HH:mm")
      ),
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Chip
          size="small"
          label={params.value}
          className={getColorState(params.value)}
        />
      ),
    },
    {
      field: 'username',
      headerName: 'Creador',
      flex: 1,
      renderCell: (params) => (
        params.value ? params.value : ''
      ),
    },
  ];

  const renderError = () => (
    <>
      <Alert severity="error">
          {message}
      </Alert>
      <NeokodeButton
        type="button"
        variant="contained"
        className={classes.button}
        component={RouterLink}
        to="/"
      >
        Volver al inicio
      </NeokodeButton>
    </>
  );

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Tickets de Soporte
          </Typography>

          {
            isGlobalError ?
            renderError()
            :
            <>
              <FilterPanel onFilter={handleFilter} />
              <div style={{ height: '690px', width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row.id}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  loading={isLoading}
                  onRowClick={handleRowClick}
                  disableSelectionOnClick
                  getRowClassName={() => 'clickable-row'}
                  components={{
                    NoRowsOverlay: NoRowsOverlay,
                    Toolbar: QuickSearchToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(''),
                      onRefresh: () => getTickets(),
                      isLoading: isLoading,
                    },
                  }}
                  localeText={esES.props.MuiDataGrid.localeText}
                />
              </div>
            </>
          }
        </Paper>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default TicketsPage;