import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Footer from './Footer';
import HomePage from '../pages/HomePage';
import TicketsPage from '../pages/TicketsPage';
import TicketPage from '../pages/TicketPage';
import NotFoundPage from '../pages/NotFoundPage';
import RedirectLogin from '../components/RedirectLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        '& > div:nth-of-type(2)': {
            minHeight: 'calc(100% - 198px)',
        }
    },
}));

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                rest.isAuthenticated ? (
                    <Component {...props} params={rest}/>
                ) : (
                    <RedirectLogin
                        to={`/login?redirect=${window.location.origin}${props.location.pathname}${props.location.search}`}
                    />
                )
            }
        />
    );
};

const Layout = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState('');

    useEffect(() => {
        checkAuthState();
    }, []);

    async function getAccess() {
        try {
            const response = await invokeApig({
                app: 'admin',
                path: '/user/profile'
            });
            if (response.code === 0) {
                setAccess(response.data.user_access);
                setCompany(response.data.company);
            }
        } catch (error) {
            console.error('Error in getAccess:', error);
        }
    }

    const checkAuthState = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setCurrentUser(user);
            setIsAuthenticated(true);
            await getAccess();
            setIsLoading(false);
        } catch (err) {
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    };

    return (
        isLoading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
        :
        (
            isAuthenticated ?
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <Header user={currentUser} isAuthenticated={isAuthenticated} access={access} company={company} />
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Switch>
                            <PrivateRoute exact path="/" component={HomePage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/tickets" component={TicketsPage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/tickets/create" component={TicketPage} isAuthenticated={isAuthenticated} access={access} />
                            <PrivateRoute exact path="/tickets/edit/:id" component={TicketPage} isAuthenticated={isAuthenticated} access={access} />
                            {/*
                            <PrivateRoute exact path="/docs" component={DocumentsPage} isAuthenticated={isAuthenticated} access={access} />
                            */}
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Footer />
                    </main>
                </div>      
            </Router>
            :
            <Router>
                <Switch>
                    <Route component={NotFoundPage} />
                </Switch>
            </Router>
        )
    );
};

export default Layout;
