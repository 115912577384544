import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Grid, Card, CardContent, Typography, Avatar, Paper, CircularProgress, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
	welcome: {
		marginTop: '15px',
	},
	userCard: {
		backgroundColor: '#383838',
		color: '#fff',
		textAlign: 'center',
	},
	avatar: {
		backgroundColor: '#00b31f',
		width: theme.spacing(7),
		height: theme.spacing(7),
		margin: 'auto'
	},
	avatarSkeleton: {
		margin: '0 auto'
	},
	section: {
		margin: theme.spacing(3, 0),
		marginBottom: theme.spacing(10),
		width: 'inherit',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	gridMax: {
		[theme.breakpoints.up('md')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	text: {
		marginBlockEnd: '16px',
		marginBlockStart: '16px',
	},
	button: {
		marginTop: theme.spacing(2),
	},
	successAlert: {
		width: '100%'
	},
	btnAction: {
		margin: '0 10px'
	},
	btnTickets: {
		textDecoration: 'none',
	},
	buttonsCard: {
		textAlign: 'center',
	},
	buttonContainer: {
		marginTop: '15px',
	},
	findButton: {
		backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2086e3',
    },
	}
}));

function HomePage(props) {
	const classes = useStyles();
	const [userInfo, setUserInfo] = useState({});
	const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);

	useEffect(() => {
		getUserInfo();
	}, []);

	const getUserInfo = async () => {
		try {
			const userData = await Auth.currentAuthenticatedUser();
			setUserInfo(userData);
		} catch (error) {
			console.error('Error al obtener los datos del usuario: ', error);
		}
		setIsLoadingUserInfo(false);
	}

	return (
		<Grid container spacing={2} className={classes.section} justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMax}>
				<Grid container spacing={2}>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Card className={classes.userCard}>
							<CardContent>
								{
									isLoadingUserInfo ?
										<div>
											<Skeleton variant="circle" width={56} height={56} className={classes.avatarSkeleton}/>
											<Skeleton variant="text" height={47} />
											<Skeleton variant="text" height={32} />
										</div>
										:
										(
											(userInfo && userInfo.attributes) &&
											<>
												<Avatar className={classes.avatar}>
													<Icons.AccountCircle />
												</Avatar>
												<Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
												<Typography variant="h6">{userInfo.attributes.email}</Typography>
											</>
										)
								}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8} className={classes.section2}>
						<Card className={classes.userCard2}>
							<CardContent>
								<Typography variant="h4" className={classes.welcome}><b>Support App</b></Typography>
								<Typography className={classes.text}>
									Gestiona tus tickets de Soporte. Infora errores, realiza sugerencias y aclara tus dudas consultando en esta App.
								</Typography>
								<Typography className={classes.text}>
									Podrás hacer seguimiento a tus tickets, filtrar y ver el historial.
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4} className={classes.section3}>
						<Card className={classes.buttonsCard}>
							<CardContent>
								<Typography variant="h5">
									Accesos rápidos
								</Typography>
								<Typography variant="h5" className={classes.buttonContainer}>
									<NeokodeButton
										variant="contained"
										className={classes.addButton}
										startIcon={<Icons.Add />}
										component={RouterLink}
										to="/tickets/create"
									>
										Agregar Ticket
									</NeokodeButton>
								</Typography>
								<Typography variant="h5" className={classes.buttonContainer}>
									<Button
										variant="contained"
										className={classes.findButton}
										startIcon={<Icons.Search />}
										component={RouterLink}
										to="/tickets"
									>
										Consultar Tickets
									</Button>
								</Typography>
							</CardContent>
						</Card>
					</Grid>

				</Grid>
			</Grid>
		</Grid>
	);
}
export default HomePage;