import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Paper, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { invokeApig } from '../../libs/awsLib';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2086e3',
    },
  },
  autocomplete: {
    '& .MuiInputBase-input': {
      width: '100%',
    }
  },
}));

const FilterPanel = ({ onFilter }) => {
  const classes = useStyles();
  const states = [
    {value: '', label: 'Todos'},
    {value: 'Sin asignar', label: 'Sin asignar'},
    {value: 'En progreso', label: 'En progreso'},
    {value: 'Resuelto', label: 'Resuelto'},
  ]
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [statusSel, setStatusSel] = useState({value: '', label: 'Todos'});

  const handleFilter = () => {
    const status = statusSel ? statusSel.value : undefined;
    onFilter({ startDate, endDate, status });
  };

  const today = moment().format('YYYY-MM-DD')

  return (
    <Paper>
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            label="Fecha Inicial"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: "2024-01-01",
              max: endDate || today,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Fecha Final"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: startDate || today,
              max: today,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            options={states}
            getOptionLabel={(option) => option.label || ''}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField {...params} label="Estado" className={classes.autocomplete} />
            )}
            value={statusSel}
            onChange={(event, newValue) => setStatusSel(newValue)}
          />
        </FormControl>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleFilter}
        >
          Filtrar
        </Button>
      </div>
    </Paper>
  );
};

export default FilterPanel;