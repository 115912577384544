import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  client: {
    textAlign: 'left',
    marginTop: '10px',
    backgroundColor: '#e0f7fa',
  },
  support: {
    textAlign: 'right',
    marginTop: '10px',
    backgroundColor: '#f1f8e9',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CommentsPanel = ({comments}) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {
        comments.length === 0 ?
        <ListItemText
            primary="No hay comentarios" />
        :
        comments.map((comment, index) => (
          <ListItem key={index} className={comment.type !== 'support' ? classes.client : classes.support}>
            {
              (comment.type !== 'support') &&
              <ListItemAvatar className={classes.avatar}>
                <Avatar>
                  <Icons.Person />
                </Avatar>
              </ListItemAvatar>
            }
            <ListItemText
              primary={`${comment.message}`}
              secondary={
                <>
                  {`${comment.username ? comment.username + ' <' + comment.useremail + '>' : ''}`}
                  <br/>
                  <Typography component="span" variant="body2" color="textPrimary">
                    {moment(comment.creationDate).utcOffset(comment.creationDateZone).format('DD-MM-YYYY HH:mm')}
                  </Typography>
                </>
              }
              style={{ whiteSpace: 'pre-wrap' }}
            />
            {
              (comment.type === 'support') &&
              <ListItemAvatar className={classes.avatar}>
                <Avatar>
                  <Icons.HeadsetMic />
                </Avatar>
              </ListItemAvatar>
            }
          </ListItem>
        ))
      }
    </List>
  );
};

export default CommentsPanel;